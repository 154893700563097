import React from 'react';

const GoogleMap = () => {
    return (
        <>
            <iframe title="location" src="
            https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=413%20Birmingham%20Rd,%20Alvechurch,%20Redditch%20B97%206RL,%20UK+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" width="100%" height="450" allowFullScreen="" loading="lazy"/>
        </>
    )
}

export default GoogleMap;
