import React, { useRef } from "react"
import emailjs from '@emailjs/browser';
import ContactImg from "../../assets/images/contact-img.png";
import {NotificationContainer, NotificationManager} from 'react-notifications';

const ContactForm = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_8skkwx3', 'template_b1bkub8', form.current, '0tB_YfkGLfZ8GplAG')
          .then((result) => {
              NotificationManager.success('Mail sent!. We will get back to you soon.');
              window.location.reload();
          }, (error) => {
              NotificationManager.info('Please email us to enquires@doitcloud.co.uk', 'Server down.', 15000);
              console.log(error.text);
          });
    };
    return (
        <div className="contact-area ptb-80 bg-f7fafd">
            <div className="container">
                <div className="section-title">
                    <h2>Get In Touch With Us</h2>
                    <div className="bar"/>
                    <p>Anything On your Mind. We’ll Be Glad To Assist You!</p>
                </div>

                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <img src={ContactImg} alt="contact us" />
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <form ref={form} name="contact" onSubmit={sendEmail} id="contactForm">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="from_name"
                                            placeholder="Your Name"
                                            className="form-control"
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="user_email"
                                            placeholder="Your email address"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="mobile"
                                            placeholder="Your phone number"
                                            className="form-control"
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            name="subject"
                                            placeholder="Your Subject"
                                            className="form-control"
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <textarea
                                            name="message"
                                            cols="30"
                                            rows="5"
                                            placeholder="Write your message..."
                                            className="form-control"
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-12 col-sm-12">
                                    <button type="submit" onClick={() => {
                                    }}
                                        className="btn btn-primary">Send Message</button>
                                </div>
                                {            <NotificationContainer/>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactForm;
