import React from 'react';
import * as Icon from 'react-feather';

const ContactInfo = () => {
    return (
        <div className="contact-info-area ptb-80 bg-f7fafd">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="contact-info-box">
                            <div className="icon">
                                <Icon.Mail />
                            </div>
                            <h3>Mail Here</h3>
                            <p><a href="mailto:enquires@doitcloud.co.uk">enquires@doitcloud.co.uk</a></p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="contact-info-box">
                            <div className="icon">
                                <Icon.MapPin />
                            </div>
                            <h3>UK Office</h3>
                            <p>VISTA HOUSE, 413 BIRMINGHAM ROAD,
                                <br /> REDDITCH, ENGLAND, B97 6RL <br />
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="contact-info-box">
                            <div className="icon">
                                <Icon.MapPin />
                            </div>
                            <h3>Australia Office</h3>
                            <p>45 Clarke St,
                                <br /> Melbourne <br />
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="contact-info-box">
                            <div className="icon">
                                <Icon.MapPin />
                            </div>
                            <h3>India Office</h3>
                            <p>Suite 5, Rajendar Place,
                                <br /> Noida 110008<br />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo;
